import axios from "axios";
/**
 * @description: base64 to blob
 */
export function dataURLtoBlob(base64Buf: string): Blob {
  const arr = base64Buf.split(",");
  const typeItem = arr[0];
  const mime = (typeItem.match as any)(/:(.*?);/)[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function convertImageUrlToBase64(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    // 因video缩略图的图片特殊编码特性问题，必须使用canvas转一下
    // axios({
    //   url,
    //   method: "GET",
    //   responseType: "blob", // 重要：设置响应类型为blob
    // })
    //   .then((response) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(response.data);
    //     reader.onloadend = function () {
    //       const base64data = reader.result as string;
    //       resolve(base64data);
    //     };
    //   })
    //   .catch((error) => reject(error));
    // 创建新的Image对象
    const image = new Image();

    // 设置跨域属性，如果图片与你的服务器不是同源的话
    image.crossOrigin = "Anonymous";

    // 当图片加载完成时
    image.onload = () => resolve(getBase64Image(image));
    image.onerror = function () {
      reject(url);
    };
    image.src = url;
    // 如果图片已经被缓存，则直接触发onload
    if (image.complete) {
      resolve(getBase64Image(image));
    }
  });
}
/**
 * @description: 获取base64
 */
export function getBase64Image(image: HTMLImageElement) {
  // 创建canvas
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;

  // 将图片绘制到canvas上
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(image, 0, 0, image.width, image.height);

  // 转换为Base64
  const dataURL = canvas.toDataURL("image/png");
  return dataURL;
}

// base64转file对象
export function base64ToFile(base64Data: string, filename?: string) {
  const arr = base64Data.split(",");
  const typeItem = arr[0];
  const mime: string = (typeItem.match as any)(/:(.*?);/)[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime });
  filename = filename ? filename : mime;
  // 创建File对象
  const file = new File([blob], filename, { type: mime });

  return file;
}

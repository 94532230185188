import type { AxiosRequestConfig, AxiosResponse } from "axios";
import { message as AntMessage } from "ant-design-vue";
import { useUserStore, useLocaleStore } from "@/store";
import { RequestOptions, Result } from "@/types/axios";
import { RequestEnum, ResultEnum } from "@/enums/httpEnum";
import { useI18n } from "@/hooks/useI18n";
import { isFunction } from "@/utils/is";
import { joinTimestamp } from "./helper";
import { AxiosRetry } from "./axiosRetry";
import { checkStatus } from "./checkStatus";

// 请求之前处理config
export function beforeRequestHook(
  config: AxiosRequestConfig,
  options: Recordable
) {
  const { joinPrefix, joinTime = true, urlPrefix } = options || {};
  if (joinPrefix) {
    config.url = `${urlPrefix}${config.url}`;
  }

  const params = config.params;
  if (config.method?.toUpperCase() === RequestEnum.GET) {
    config.params = Object.assign(params || {}, joinTimestamp(joinTime, false));
  }

  const { cancelerRequest } = options;
  if (cancelerRequest && isFunction(cancelerRequest)) {
    cancelerRequest((config as any)?.axiosCanceler, config);
  }

  return config;
}

/**
 * @description: 请求拦截器处理
 */
export function requestInterceptors(config: any) {
  // 请求之前处理config
  const { getToken: token } = useUserStore();
  const { getLanguage: language } = useLocaleStore();
  if (token && config?.requestOptions?.withToken !== false) {
    config.headers.Authorization = token;
  }
  if (language) {
    config.headers["Accept-Language"] = language;
  }
  const { lastLoginParams = {} } = useUserStore();
  Object.assign(config.headers, lastLoginParams);
  return config;
}

/**
 * @description: 请求拦截器错误捕获
 */
export function requestInterceptorsCatch(error: Error) {
  console.error(error);
}

/**
 * @description: 处理响应数据。如果数据不是预期格式，可直接抛出错误
 */
export function transformResponseHook(
  res: AxiosResponse<Result>,
  options: RequestOptions
) {
  const { t } = useI18n();
  const {
    isTransformResponse = true,
    isReturnNativeResponse,
    isMessgae = true,
  } = options;
  // 是否返回原生响应头 比如：需要获取响应头时使用该属性
  if (isReturnNativeResponse) {
    return res;
  }
  // 不进行任何处理，直接返回
  // 用于页面代码可能需要直接获取code，data，message这些信息时开启
  if (!isTransformResponse) {
    return res.data;
  }
  // 错误的时候返回

  const { data } = res;
  if (!data) {
    // return '[HTTP] Request has no return value';
    throw new Error(t("sys.api.apiRequestFailed"));
  }
  //  这里 code，data，msg为 后台统一的字段，需要在 types.ts内修改为项目自己的接口返回格式
  const { code, data: result = true, msg: message } = data;

  // 这里逻辑可以根据项目进行修改
  const hasSuccess =
    data && Reflect.has(data, "code") && code == ResultEnum.SUCCESS;
  if (hasSuccess) {
    return result;
  }

  // 在此处根据自己项目的实际情况对不同的code执行不同的操作
  // 如果不希望中断当前请求，请return数据，否则直接抛出异常即可
  let timeoutMsg = t("sys.api.apiRequestFailed");
  switch (code) {
    case ResultEnum.TIMEOUT:
      timeoutMsg = message || t("sys.api.timeoutMessage");
      const userStore = useUserStore();
      userStore.setToken(undefined);
      if (window.self !== window.top) {
        // 页面被嵌套在 iframe 中
        window.parent.postMessage(
          {
            type: "loginTimeout",
          },
          "*"
        );
        return true;
      } else {
        userStore.loginOut();
      }
      break;
    default:
      if (message) {
        timeoutMsg = message;
      }
  }

  if (isMessgae) {
    AntMessage.error({ content: timeoutMsg, key: "api" });
  }

  throw new Error(timeoutMsg);
}

/**
 * @description: 响应拦截器处理
 */
export function responseInterceptors(res: AxiosResponse<any>) {
  return res;
}

/**
 * @description: 响应错误处理
 */
export function responseInterceptorsCatch(service: AxiosResponse, error: any) {
  const { t } = useI18n();
  // errorLogStore.addAjaxErrorInfo(error);
  const { response, code, message, config } = error || {};
  const msg: string = response?.data?.error?.message ?? "";
  const err: string = error?.toString?.() ?? "";
  const isMessgae = config?.requestOptions?.isMessgae;
  let errMessage = "";

  try {
    if (code === "ECONNABORTED" && message.indexOf("timeout") !== -1) {
      errMessage = t("sys.api.apiTimeoutMessage");
    }
    if (err?.includes("Network Error")) {
      errMessage = t("sys.api.networkExceptionMsg");
    }

    if (isMessgae && errMessage) {
      AntMessage.error(errMessage);
      return Promise.reject(error);
    }
  } catch (error) {
    throw new Error(error as unknown as string);
  }

  if (isMessgae) {
    checkStatus(error?.response?.status, msg);
  }

  // 添加自动重试机制 保险起见 只针对GET请求
  const retryRequest = new AxiosRetry();
  const { isOpenRetry } = config?.requestOptions?.retryRequest || {};
  config.method?.toUpperCase() === RequestEnum.GET &&
    isOpenRetry &&
    retryRequest.retry(service as any, error);
  return Promise.reject(error);
}

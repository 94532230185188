import TOSClient from "@volcengine/tos-sdk";
import { CheckpointRecord } from "@volcengine/tos-sdk/dist/methods/object/multipart/uploadFile";
import { getUploadSecret } from "@/api/upload";
import { FileState, FileStatus } from "@/types/upload";

type TOSClientType = InstanceType<typeof TOSClient>;

type CheckPointAction = {
  getCheckPoints: () => any;
  addCheckpoint: (key: string, checkpoint: Recordable) => void;
  removeCheckPoint: (key: string) => void;
};

const partSize = 5 * 1024 * 1024; // 每个分片大小(byte)
const taskNum = 3; // 同时上传的分片数

let tosClient: TOSClientType | null = null; // oss客户端实例
let tosCheckPointAction: CheckPointAction | null = null; //断点实例
let tosSecret: any = null; //密钥凭证

const tosCancelSource = {} as Record<string, Recordable>; //终止上传token

// 生成client
async function initTOSClient() {
  const secret = await getUploadSecret();
  tosSecret = secret;
  tosClient = new TOSClient({
    bucket: secret.bucketName,
    region: secret.region,
    endpoint: secret.endPoint,
    accessKeyId: secret.ak,
    accessKeySecret: secret.sk,
  });
}

// 处理上传成功
function onUploadSuccess(fileState: FileState, result: any, key: string) {
  fileState.status = FileStatus.success;
  fileState.percent = 100;
  fileState.url = `${tosSecret.domain}${key}`;
  tosCheckPointAction?.removeCheckPoint(fileState.name);
}

// 处理上传失败
function onUploadError(fileState: FileState) {
  const key = fileState.name;
  const cancelTokenSource = tosCancelSource[key];
  cancelTokenSource?.cancel();
  fileState.status = FileStatus.fail;
}

// 分片上传进度改变回调
async function onMultipartUploadProgress(
  progress: number,
  checkpoint: CheckpointRecord,
  fileState: FileState
) {
  fileState.uploadId = checkpoint.upload_id;
  fileState.percent = Math.floor(progress * 100);
  fileState.status = FileStatus.processing;
  tosCheckPointAction?.addCheckpoint(fileState.name, checkpoint);
}

// 普通上传
async function commonUpload(fileState: FileState, file: File, key: string) {
  return tosClient
    ?.putObject({ key, body: file })
    .then((result: any) => {
      onUploadSuccess(fileState, result, key);
    })
    .catch((err) => {
      fileState.status = FileStatus.fail;
    });
}

// 分片上传
async function multipartUpload(fileState: FileState, file: File, key: string) {
  const cancelTokenSource = TOSClient.CancelToken.source();
  tosCancelSource[key] = cancelTokenSource;
  return tosClient
    ?.uploadFile({
      key,
      file,
      partSize,
      taskNum,
      cancelToken: cancelTokenSource.token,
      progress: (progress, checkpoint) =>
        onMultipartUploadProgress(progress, checkpoint, fileState),
    })
    .then((result: any) => {
      onUploadSuccess(fileState, result, key);
    })
    .catch((err) => {
      onUploadError(fileState);
    });
}

// 断点续传
async function resumeMultipartUpload(
  fileState: FileState,
  file: File,
  key: string
) {
  const cancelTokenSource = TOSClient.CancelToken.source();
  const checkpoints = tosCheckPointAction?.getCheckPoints() || {};
  const checkpoint = checkpoints[fileState.name];
  tosCancelSource[key] = cancelTokenSource;
  fileState.status = FileStatus.processing;
  tosClient
    ?.uploadFile({
      key,
      file,
      partSize,
      taskNum,
      checkpoint,
      cancelToken: cancelTokenSource.token,
      progress: (progress, checkpoint) =>
        onMultipartUploadProgress(progress, checkpoint, fileState),
    })
    .then((result) => {
      onUploadSuccess(fileState, result, key);
    })
    .catch((err) => {
      onUploadError(fileState);
    });
}

// 存在的上传
function existUpload(fileState: FileState, result: any, key: string) {
  onUploadSuccess(fileState, result, key);
}

// 文件是否存在
async function isExistFile(key: string) {
  try {
    return await tosClient?.headObject({
      bucket: "vsais-platform",
      key,
    });
  } catch (error: any) {
    return undefined;
  }
}

// 火山引擎分片上传文件
export async function tosRequest(
  fileState: FileState,
  file: File,
  key: string, //文件路径
  action: CheckPointAction
) {
  // 这些状态直接返回
  if (
    [
      FileStatus.success,
      FileStatus.processing,
      FileStatus.calculating,
    ].includes(fileState.status)
  )
    return;

  // 已经上传过文件
  if (fileState.url) {
    fileState.percent = 100;
    fileState.status = FileStatus.success;
    return;
  }

  try {
    if (!tosClient) {
      await initTOSClient();
    }

    if (!tosCheckPointAction) {
      tosCheckPointAction = action;
    }

    const result = await isExistFile(key);
    if (result) {
      // 文件存在
      existUpload(fileState, result, key);
    } else if (file?.size < partSize) {
      // 如果文件大小小于分片大小，使用普通上传，否则使用分片上传
      commonUpload(fileState, file, key);
    } else if (fileState.uploadId) {
      // 文件断点续传
      resumeMultipartUpload(fileState, file, key);
    } else {
      // 分片上传
      multipartUpload(fileState, file, key);
    }
  } catch (error) {
    fileState.status = FileStatus.fail;
    console.error(error);
  }
}

// 终止请求
export function tosAbortRequest() {
  for (const key in tosCancelSource) {
    if (Object.prototype.hasOwnProperty.call(tosCancelSource, key)) {
      const cancelTokenSource = tosCancelSource[key];
      cancelTokenSource?.cancel();
    }
  }
}

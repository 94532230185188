import { FileState, FileStatus, UploadMode } from "@/types/upload";
import { getUploadSecret } from "@/api/upload";
import aws from "aws-sdk";
import { Scheduler } from "@/utils/scheduler";

const PartSize = 5 * 1024 * 1024; //分片大小
const Concurrent = 4; //单个视频并发上传数量
let Schedulers = [] as any;

export async function awsRequest(
  fileState: FileState,
  file: any,
  key: string,
  mode: UploadMode
) {
  try {
    const secret = await getUploadSecret();
    fileState.url = `${secret.domain}${key}`;
    const config = {
      accessKeyId: secret.ak,
      secretAccessKey: secret.sk,
      region: secret.regions,
    };
    //配置秘钥信息
    aws.config.update(config);
    const awsClient = new aws.S3();
    const params = {
      Bucket: secret.bucketName,
      Key: key,
    };
    //检查文件是否已上传
    awsClient.headObject(params, async (err: any, data: any) => {
      // 没有上传成功，head方法会返回失败
      if (err) {
        //检查上传模式
        if (mode === UploadMode.init) {
          const uploadId = await initMultiPartUpload(awsClient, params);
          awsUploadPart(fileState, file, uploadId, [], key, awsClient, secret);
        } else {
          //检查是否部分上传
          const { uploadId, partsInfo } = await getAwsCheckpoint(
            key,
            awsClient,
            secret
          );
          if (uploadId) {
            //断点续传
            awsUploadPart(
              fileState,
              file,
              uploadId,
              partsInfo.Parts,
              key,
              awsClient,
              secret
            );
          } else {
            //初始化文件上传
            const uploadId = await initMultiPartUpload(awsClient, params);
            awsUploadPart(
              fileState,
              file,
              uploadId,
              [],
              key,
              awsClient,
              secret
            );
          }
        }
      } else {
        //data存在，上传成功
        fileState.percent = 100;
        fileState.status = FileStatus.success;
      }
    });
  } catch (err: any) {
    fileState.status = FileStatus.fail;
    console.log(err);
  }
}
//获取当前文件是否有已上传断点信息
async function getAwsCheckpoint(
  key: string,
  awsClient: any,
  sk: any
): Promise<any> {
  let uploadId = "";
  let partsInfo;
  try {
    const result = await awsClient
      .listMultipartUploads({
        Bucket: sk.bucketName,
        Prefix: key,
      })
      .promise();
    if (result.Uploads.length) {
      uploadId = result.Uploads[result.Uploads.length - 1].UploadId; //获取具体分片信息
      partsInfo = await awsClient
        .listParts({
          Bucket: sk.bucketName,
          Key: key,
          UploadId: uploadId,
        })
        .promise();
    }
  } catch (err: any) {
    console.log(err);
  }
  return { uploadId, partsInfo };
}
//初始化分片上传
async function initMultiPartUpload(awsClient: any, params: any) {
  const result = await awsClient.createMultipartUpload(params).promise();
  return result.UploadId;
}

//aws断点续传-分片上传模式
async function awsUploadPart(
  fileState: FileState,
  file: File,
  uploadId: string,
  parts: any,
  key: string,
  awsClient: any,
  sk: any
) {
  //已完成的分片
  const completeParts = parts?.map((_: any) => {
    return { PartNumber: _.PartNumber, ETag: _.ETag };
  });
  const count = Math.ceil(file.size / PartSize);
  const partNumbers = parts.map((_: any) => _.PartNumber) || [];
  fileState.status = FileStatus.processing;
  if (partNumbers.length) {
    fileState.percent = parseInt((completeParts.length * 100) / count);
  }
  let startTime = null as any;
  const scheduler = new Scheduler(Concurrent);

  for (let n = 1; n <= count; n++) {
    if (!startTime) {
      startTime = new Date();
    }
    const start = (n - 1) * PartSize;
    const end = Math.min(start + PartSize, file.size) - 1;
    if (!partNumbers.includes(n)) {
      scheduler.add(() => {
        const promise = awsClient
          .uploadPart({
            Bucket: sk.bucketName,
            Key: key,
            UploadId: uploadId,
            PartNumber: n,
            Body: file.slice(start, end + 1),
          })
          .promise()
          .then((data: any) => {
            completeParts.push({ PartNumber: n, ETag: data.ETag });
            fileState.percent = parseInt((completeParts.length * 100) / count);
          })
          .catch((err: any) => {
            fileState.status = FileStatus.fail;
            throw err;
          });
        return promise;
      });
    }
  }
  Schedulers.push(scheduler);
  //所有分片上传完成后手动合并
  scheduler
    .taskStart()
    .then(async () => {
      if (count === completeParts.length) {
        completeMultiUpload(
          uploadId,
          completeParts,
          fileState,
          key,
          awsClient,
          sk
        );
      }
    })
    .catch(() => {
      fileState.status = FileStatus.fail;
    });
}
//aws合并分片校验
function completeMultiUpload(
  uploadId: any,
  parts: any,
  fileState: FileState,
  key: any,
  awsClient: any,
  sk: any
) {
  //分片信息按PartNumber顺序排序
  const newParts = parts.sort((a: any, b: any) => a.PartNumber - b.PartNumber);
  awsClient
    .completeMultipartUpload({
      Bucket: sk.bucketName,
      UploadId: uploadId,
      MultipartUpload: {
        Parts: newParts,
      },
      Key: key,
    })
    .promise()
    .then(() => {
      fileState.status = FileStatus.success;
      fileState.percent = 100;
    })
    .catch(() => {
      fileState.status = FileStatus.fail;
    });
}
// 终止请求
export function awsAbortRequest() {
  Schedulers.forEach((item: any) => {
    item.clearQueue();
  });
  Schedulers = [];
}

import { defineStore } from "pinia";
import {
  updatePatrolUpload,
  updateUploadMode,
  getPatrolMode,
} from "@/api/patrol";
import { useUserStore, useAppStore } from "@/store";
import { tosRequest, tosAbortRequest } from "@/hooks/upload/useTosUpload";
import { hwRequest, hwAbortRequest } from "@/hooks/upload/useHwUpload";
import { awsRequest, awsAbortRequest } from "@/hooks/upload/useAwsUpload";
import { minioRequest, minioAbortRequest } from "@/hooks/upload/useMinioUpload";
import { ObsTypeEnum } from "@/enums/appEnum";
import {
  FileStatus,
  UploadMode,
  FileState,
  PatrolState,
  uploadState,
} from "@/types/upload";

export const useUploadStore = defineStore("app-upload", {
  state: (): uploadState => {
    return {
      pendingList: [],
      uploadList: [],
      successList: [],
      subscription: {},
      checkpoints: {},
    };
  },
  getters: {},
  actions: {
    //设置等待队列
    setPendingFiles(files: PatrolState[]) {
      this.pendingList.push(...files);
      //上传视频主动触发
      this.setUploadFiles();
    },
    clearFiles() {
      this.uploadList = [];
      this.successList = [];
    },
    //设置正在上传队列并开始上传 retry为true强制断点续传
    async setUploadFiles(retry = false) {
      if (this.uploadList.length > 0 || this.pendingList.length === 0) return;
      const patrol: any = this.pendingList.shift();
      this.uploadList.push(patrol);
      //获取上传模式
      if (retry) {
        this.subscription[patrol.no] = UploadMode.reUpload;
      } else {
        const { mode = UploadMode.reUpload } = await getPatrolMode({
          no: patrol.no,
        });
        this.subscription[patrol.no] = mode;
      }
      //首次上传初始化模式，默认使用续传
      patrol.fileStatusList?.forEach((fileState: FileState) => {
        //更改文件状态
        const key = `${patrol.projectId}/track/${fileState.name}`;
        const file = patrol.fileList.find((item: File) => {
          return item.name == fileState.name;
        });
        const checkpoint = this.checkpoints[fileState.name] || {};
        fileState.uploadId = checkpoint?.upload_id;
        this.obsRequest(fileState, file, key, this.subscription[patrol.no]);
      });

      //更新操作时间
      updatePatrolUpload({
        no: patrol.no,
        operationRecord: {
          operateTime: new Date(),
          no: patrol.uuid,
          track: 4,
        },
      });
    },

    // 请求分类
    async obsRequest(
      fileState: FileState,
      file: File,
      key: string,
      subscription: UploadMode
    ) {
      const useApp = useAppStore();
      const obsType = useApp.appConfig?.obsType;
      const action = {
        getCheckPoints: this.getCheckPoints,
        addCheckpoint: this.addCheckpoint,
        removeCheckPoint: this.removeCheckPoint,
      };
      // 无网络请求
      if (!window.navigator.onLine) {
        return;
      }
      if (obsType === ObsTypeEnum.tos) {
        return tosRequest(fileState, file, key, action);
      } else if (obsType === ObsTypeEnum.hw) {
        return hwRequest(fileState, file, key);
      } else if (obsType === ObsTypeEnum.aws) {
        return awsRequest(fileState, file, key, subscription);
      } else if (obsType === ObsTypeEnum.minio) {
        return minioRequest(fileState, file, key);
      }
    },

    //计算当前巡检记录的平均进度
    calAvePercent(patrol: PatrolState) {
      let totalAmount = 0; //总上传大小
      let transferredAmount = 0; //已传输
      patrol?.fileStatusList.forEach((_file: FileState) => {
        if (
          [FileStatus.processing, FileStatus.success, FileStatus.done].includes(
            _file.status
          )
        ) {
          totalAmount += _file.totalSize;
          transferredAmount += _file.totalSize * _file.percent;
        }
      });
      return totalAmount == 0 ? 0 : transferredAmount / totalAmount;
    },

    //单笔巡检记录下视频全部上传成功
    async uploadSuccess(patrolUpload: PatrolState) {
      const urlList = patrolUpload.fileStatusList.map(
        (file: FileState) => file.url
      );
      const userStore = useUserStore();
      const userInfo: any = userStore.geStoretUserInfo;
      const { userName, nickName = "" } = userInfo;
      //更新巡检记录
      const params = {
        fileName: patrolUpload.fileStatusList[0]?.name.slice(4, 19),
        uploadUrl: urlList.join(","),
        no: patrolUpload.no,
        track: 4,
        operator: nickName || userName,
        operationRecord: {
          operateEndTime: new Date(),
          no: patrolUpload.uuid,
          track: 4,
        },
      };
      //更新后端接口
      await updatePatrolUpload(params);
      patrolUpload.fileStatusList.forEach((file: FileState) => {
        file.status = FileStatus.done;
      });
      this.successList.push(patrolUpload);
      this.uploadList = [];
    },
    //上传重试
    async reUpload(patrol: PatrolState) {
      //上传中队列置空
      this.uploadList = [];
      const abortFiles = [] as any[];
      patrol.fileStatusList.forEach((item: FileState) => {
        if (item.status == FileStatus.fail) {
          item.status = FileStatus.pending;
          abortFiles.push(item.md5);
        }
      });
      this.abortRequest(abortFiles); //先终止再上传
      this.pendingList.unshift(patrol);
      this.setUploadFiles(true);
    },

    //手动清空调接口更新文件二次上传状态
    async updateUploadMode(patrol: PatrolState) {
      //更新no中mode
      await updateUploadMode({
        no: patrol.no,
        mode: UploadMode.init,
      });
      // 获取所有文件的md5
      const deleteFiles = this.getFilesMd5(this.uploadList);
      this.uploadList = [];
      this.abortRequest(undefined, deleteFiles); //先终止再上传
      this.setUploadFiles();
    },

    // 获取文件的md5
    getFilesMd5(array: any[]) {
      const newArr = [] as any[];
      array.forEach((item) => {
        item.fileStatusList.forEach((v: FileState) => {
          if (v.md5) {
            newArr.push(v.md5);
          }
        });
      });
      return newArr;
    },
    // 获取文件上传的检查点
    getCheckPoints() {
      return this.checkpoints;
    },
    // 设置上传文件的检查点
    addCheckpoint(key: string, checkpoint: Recordable) {
      if (checkpoint) {
        this.checkpoints[key] = checkpoint;
      }
    },
    // 删除上传文件的检查点
    removeCheckPoint(key: string) {
      if (key) {
        delete this.checkpoints[key];
      }
    },
    // 终止请求分类
    async abortRequest(abortFiles?: string[], deleteFiles?: string[]) {
      const useApp = useAppStore();
      const obsType = useApp.appConfig?.obsType;
      if (obsType === ObsTypeEnum.tos) {
        await tosAbortRequest();
      } else if (obsType === ObsTypeEnum.hw) {
        await hwAbortRequest();
      } else if (obsType === ObsTypeEnum.aws) {
        await awsAbortRequest();
      } else if (obsType === ObsTypeEnum.minio) {
        await minioAbortRequest(abortFiles, deleteFiles);
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ["checkpoints"], // 存储属性 若不填写全缓存
      },
    ],
  },
});

//文件处理状态枚举
export enum FileStatus {
  "pending",
  "calculating",
  "processing",
  "success",
  "done",
  "fail",
}
export enum UploadMode {
  "init",
  "reUpload",
}
//文件类型
export type FileState = {
  name: string; //文件名称
  uid: string; //文件uid
  status: FileStatus; //文件处理状态
  percent: number; //文件上传进度
  uploadSpeed?: string; //文件上传速率
  uploadedSize?: number; //已上传的文件大小
  totalSize: number; //文件大小
  url: string; //文件url
  uploadId?: string; //上传文件id;
  no?: string; //父级的编号
  md5?: string; //文件的md5
  chunkFileList?: File[]; // 分片的文件
  path?: string; //上传的文件路径
};
//巡检记录类型
export type PatrolState = {
  no: string; //巡检记录编号
  uuid: string; //巡检记录uuid
  projectId: string; //巡检记录对应的projectId
  fileStatusList: FileState[]; //巡检记录里视频状态列表
  fileList: File[]; //巡检视频里文件列表
  avePervent: number; //巡检记录中视频平均进度
};
export interface uploadState {
  pendingList: PatrolState[]; //等待处理的文件队列
  uploadList: PatrolState[]; //上传中的巡检记录 数组长度0/1
  successList: PatrolState[]; //上传成功的队列，全部上传成功后置空
  subscription: any; //记录fileName上传模式等信息
  checkpoints: Record<string, Recordable>; //上传的断点信息
}
